import React, { useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";


function DeadEndMFa() {
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const deadEndMfaActive = useStoreState((state) => state.deadEndMfaSms);
    const setDeadEndMfaSms = useStoreActions(actions => actions.setDeadEndMfaSms);
    const history = useHistory();
    const sessionTimeoutId = useStoreState(state => state.sessionTimeoutId);
    const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
    useEffect(() => {
        if(deadEndMfaActive === true){
            clearInterval(sessionTimeoutId)
            const triggerAbandonModal = 
                setInterval(() => {
                
                    setApplicationResult({result : 'MFASESSIONEXPIRED'});
                    setdisplayResult(true)
                    setDeadEndMfaSms(false)
                    window.scrollTo(0, 0);
                    history.push(`/declined`+window.location.search)
                        clearInterval(triggerAbandonModal);
                    
                },5000);
        
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [deadEndMfaActive]);
     


    return (
        <>
        <Modal show={deadEndMfaActive} backdrop="static" id="mfa-modal-end" onHide={() => true} centered  >
           
            <Modal.Body  className="font18">
                <div className="row">
                    <div className="col-12 mt-4">
                        <p className="mb-1 text-center error-message"><h1>Your session has time out.</h1></p>
                        <p className="text-center"><h2><strong>We are unable to proccess your application.</strong></h2></p>
                    </div>
                    <div className="col-12">
                      <p></p>
                    </div>
                    <div className="col-12">
                      
                   </div>
                   
                    
                </div>
                
              
            </Modal.Body>
          
        </Modal>
        </>
    );
}

export default DeadEndMFa;
